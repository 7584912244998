import axios from 'axios'
import router from '@/router'

const request = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'http://192.168.31.24:8093'
      : '/'
})

request.interceptors.request.use((config) => {
  config.headers['Content-Type'] = 'application/json'
  // 统一添加 source
  if (config.url.startsWith('http')) {
    return config
  }
  config.headers.province = encodeURIComponent(
    localStorage.getItem('province') || ''
  )
  config.headers.city = encodeURIComponent(localStorage.getItem('city') || '')
  // 统一添加 token
  const token = localStorage.getItem('token')
  if (token) {
    config.headers.token = token
  }
  return config
})

request.interceptors.response.use((res) => {
  if (res.request.responseType === 'arraybuffer') { // 用于下载
    return res
  }
  if (res.config.url.startsWith('http')) {
    return res.data
  }
  if (res.data.code === 10011 || res.data.code === 10010) {
    router.push('/login')
  }
  return res.data
})

export default request
