import Vue from 'vue'
import VueRouter from 'vue-router'

// 避免重复导航
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView')
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/LayoutView'),
    redirect: 'channel',
    children: [
      {
        path: 'channel',
        component: () => import('@/views/ChannelView')
      }
    ]
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
  if (to.name !== 'login' && !token) {
    router.push('/login')
  } else {
    next()
  }
})

export default router
