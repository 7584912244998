import Vue from 'vue'
import App from './App.vue'
import router from './router'
import moment from 'moment'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/common.less'

Vue.config.productionTip = false
Vue.use(ElementUI, { size: 'small' })
Vue.prototype.$moment = moment
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
