import request from '@/utils/request'

export const login = data => {
  return request({
    method: 'POST',
    url: '/channel/auth/login',
    data
  })
}

export const captcha = () => {
  return request({
    method: 'GET',
    url: '/channel/auth/captcha'
  })
}

export const getIp = () => {
  return request({
    method: 'GET',
    url: '/ipJson.jsp?json=true'
  })
}
